<template>
  <div>
    <simple-table
      no-data-text="Пока не привязан ни один ЛС"
      :useCustomRows="true"
      :headers="headers"
      :rows="rows"
      :showFooter="false"
    >
      <template v-slot:table-row="{ item }">
        <tr>
          <td
            v-for="(cell, key) in item"
            :key="key"
            class="text-left"
            :class="getCellClass(key)"
          >
            <v-btn
              @click="openDeleteDialog(item)"
              v-if="key === 'action'"
              elevation="2"
              x-small
              icon
            >
              <v-icon class="error--text">mdi-delete</v-icon>
            </v-btn>
            <span v-else>{{ cell }}</span>
          </td>
        </tr>
      </template>
    </simple-table>
    <confirm-dialog
      :is-open="isDialogOpen"
      title="Удаление ЛС"
      :question="deleteQuestion"
      @close-dialog="isDialogOpen = false"
      @confirm="confirm()"
      @cancel="isDialogOpen = false"
    ></confirm-dialog>
  </div>
</template>

<script>
import SimpleTable from "../../../components/tables/SimpleTable";
import { mapState } from "vuex";
import UserService from "../../../services/UserService";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

export default {
  name: "CurrentBindingsTable",
  components: { SimpleTable, ConfirmDialog },
  data() {
    return {
      headers: UserService.currentAddressesTableHeaders,
      isDialogOpen: false,
      itemToDelete: null,
    };
  },
  computed: {
    ...mapState("user", ["bindings"]),
    rows() {
      return this.bindings?.length
        ? this.getFormattedBindings(this.bindings)
        : [];
    },
    deleteQuestion() {
      if (this.itemToDelete) {
        return `Удалить привязку ЛС ${this.itemToDelete.account}?`;
      }
      return "";
    },
  },
  methods: {
    getCellClass(key) {
      return key === "id" ? "d-none" : "";
    },
    getFormattedBindings(bindings) {
      return UserService.getFormattedBindings(bindings);
    },
    log(message) {
      console.log(message);
    },
    deleteBinding(id) {
      UserService.deleteLsBinding(id);
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.isDialogOpen = true;
      // console.log(this.itemToDelete);
    },
    confirm() {
      this.deleteBinding(this.itemToDelete.id);
      this.isDialogOpen = false;
    },
  },
};
</script>

<style scoped></style>
